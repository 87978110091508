import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import tw from "twin.macro"

import Button from "../components/button"
import Container from "../components/container"
import Hero from "../components/hero"

const ServerErrorPage = () => (
  <Layout>
    <Hero styles={tw`mb-0 lg:(mb-0) xl:(mb-0)`}>
      <Container tw="text-center pt-4 pb-10 lg:(pt-6 pb-10) xl:(pt-8 pb-14)">
        <h1 tw="text-[120px] leading-[110px] font-black lg:(text-[85px] leading-[1]) xl:(text-[120px])">
          <span tw="text-[40px] leading-[62px] opacity-80 block font-bold italic mb-3 lg:(text-22 mb-0) xl:(text-32 mb-1)">
            Error{" "}
          </span>
          500
          <span tw="text-2xl leading-7 opacity-80 block font-bold italic mb-8 lg:(text-22 -mt-4 mb-4) xl:(text-32 mb-6)">
            Internal Server Error
          </span>
        </h1>
        <p tw="font-bold mb-12 lg:(mb-5) xl:(text-21 mb-7)">
          Something went wrong. Try refreshing this{" "}
          <br tw="hidden lg:(block)" />
          page or head back to the homepage.
        </p>
        <Button link="/">Back to home</Button>
      </Container>
    </Hero>
  </Layout>
)

export const Head = () => <Seo title="500 Error |  LIVTENCITY® (maribavir)" />

export default ServerErrorPage
